<!--
 *                        _oo0oo_
 *                       o8888888o
 *                       88" . "88
 *                       (| -_- |)
 *                       0\  =  /0
 *                     ___/`---'\___
 *                   .' \\|     |// '.
 *                  / \\|||  :  |||// \
 *                 / _||||| -:- |||||- \
 *                |   | \\\  - /// |   |
 *                | \_|  ''\---/''  |_/ |
 *                \  .-\__  '-'  ___/-. /
 *              ___'. .'  /--.--\  `. .'___
 *           ."" '<  `.___\_<|>_/___.' >' "".
 *          | | :  `- \`.;`\ _ /`;.`/ - ` : | |
 *          \  \ `_.   \_ __\ /__ _/   .-` /  /
 *      =====`-.____`.___ \_____/___.-`___.-'=====
 *                        `=---='
 *
 *
 *      ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *            佛祖保佑     永不宕机     永无BUG
 *
 * @Author: DY
 * @Date: 2022-11-11 14:47:38
 * @LastEditTime: 2022-11-18 15:54:18
 * @Description: 重置密码
 * @FilePath: \BusinessPlatform\src\views\login\components\resetPassword\resetPassword.vue
 -->

<template>
    <div class="resetPassword">
        <h4>重置密码</h4>
        <p>验证成功，设置新密码：</p>
        <div>
            <el-form
                ref="ref_resetPassword"
                class="form-inline"
                :rules="rules"
                :inline="true"
                :model="formInline"
            >
                <el-form-item prop="password">
                    <el-input placeholder="请输入密码" v-model="formInline.password" show-password>
                        <i slot="prefix" class="iconfont iconsuo1"></i>
                    </el-input>
                </el-form-item>
                <el-form-item prop="newPassword">
                    <el-input placeholder="请输入再次密码" v-model="formInline.newPassword" show-password>
                        <i slot="prefix" class="iconfont iconsuo1"></i>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <el-button type="primary" class="loginBtn" @click="completeReset">
            完 成
        </el-button>
    </div>
</template>

<script>
import md5 from 'js-md5';

export default {
    name: 'reset-password',
    components: {},
    props: {
        viewParameter: {
            type: [Object],
        },
    },
    data() {
        const validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                const finalCheckPwd = /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/;
                // 校验密码
                const checkPwd = finalCheckPwd.test(value);
                if (!checkPwd) {
                    callback(new Error('密码8-30位，可以使用 数字、字母、特殊字符，至少包含两种'));
                } else {
                    callback();
                }
            }
        };
        const validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.formInline.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        };
        return {
            formInline: {
                password: '',
                newPassword: '',
            },
            rules: {
                password: [
                    { validator: validatePass, trigger: 'blur' },
                ],
                newPassword: [
                    { validator: validatePass2, trigger: 'blur' },
                ],
            },
        };
    },
    watch: {},
    created() {},
    mounted() {},
    computed: {},
    methods: {
        async completeReset() {
            this.$refs.ref_resetPassword.validate(async valid => {
                if (valid) {
                    const res = await this.getVerificationCodeRequrst();
                    if (res) {
                        const nextStepData = {
                            stepNo: 3,
                        };
                        this.$emit('nextStep', nextStepData);
                    }
                }
            });
        },

        // 重置密码请求
        getVerificationCodeRequrst() {
            const requstData = {
                phone_number: this.viewParameter.phoneNumber,
                verify_code: this.viewParameter.verify_code,
                newpassword: md5(this.formInline.newPassword.toString()),
            };
            return this.$axios.$post('/interfaceApi/auth/resetpassword', requstData, { defEx: true });
        },
    },
    updated() {},
    destroyed() {},
};
</script>

<style lang="stylus" scoped>
.resetPassword
    h4
        font-size 0.32rem
        color #001A2C
    >p
        font-size 0.16rem
        line-height: 0.56rem
        color #001A2C
    >div
        height 1.3rem
        >>>.el-form-item
            width 100%
            .el-form-item__content
                width 100%
                .el-input
                    line-height: 0.36rem
                .el-form-item__error
                    white-space: nowrap
    .loginBtn
        width 100%
</style>
